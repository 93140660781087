import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/offer',
    name: 'Публичная оферта',
    component: () => import('../views/offer.vue'),
    meta: {
      auth: false,
      nav: false,
      group: 1 
    }
  },
  {
    path: '/',
    name: 'Рассылки',
    component: () => import('../views/Sender.vue'),
    meta: {
      auth: true,
      nav: true,
      group: 1
    }
  },
  {
    path: "/sender/:id",
    name: "Редактирование рассылки",
    component: () => import("../views/SenderAdd.vue")
  },
  {
    path: '/create',
    name: "Создание рассылки",
    component: () => import('../views/SenderAdd.vue')
  },
  {
    path: '/audio',
    name: 'Аудиоролики',
    component: () => import('../views/Audio.vue'),
    meta: {
      nav: true,
      group: 1
    }
  },
  {
    path: '/audio/add',
    name: 'Создание аудиоролика',
    component: () => import('../views/AudioAdd.vue')
  },
  {
    path: '/users',
    name: 'Базы клиентов',
    component: () => import('../views/Users.vue'),
    meta: {
      nav: true,
      group: 0
    }
  },
  {
    path: '/base/list',
    name: 'База',
    component: () => import('../views/BaseList.vue'),
    meta: {
      nav: true,
      group: 0
    }
  },
  {
    path: '/base/add',
    name: 'Создание базы номеров',
    component: () => import('../views/BaseAdd.vue')
  },
  {
    path: '/auth',
    name: 'Авторизация',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/user/add',
    name: 'Добавление пользователя',
    component: () => import('../views/User.vue')
  },
  {
    path: '/user/:user_id/edit',
    name: 'Редактирование пользователя',
    component: () => import('../views/User.vue')
  },
  {
    path: "/templates",
    name: "Шаблоны",
    component: () => import('../views/Templates.vue'),
    meta: {
      nav: true,
      group: 1
    }
  },
  {
    path: "/phones",
    name: "Номера отправителей",
    component: () => import('../views/Phones.vue'),
    meta: {
      nav: true,
      group: 1
    }
  },
  {
      path: '/profile',
      name: 'Профиль',
      component: () => import('../views/Profile.vue'),
      meta: {
        nav: true,
        group: 1
      }
  },

  {
    path: "/templates/create",
    name: "Создание шаблона",
    component: () => import('../views/SenderAdd.vue'),
    meta: {
      nav: false,
      group: 0,
      template: true
    }
  },
  {
    path: "/report/sender/:sender",
    name: "Отчет по рассылке",
    component: () => import("../views/report/sender.vue")
  },
  {
    path: '/base',
    name: 'Базы номеров',
    component: () => import('../views/Base.vue'),
    meta: {
      nav: true,
      group: 1
    }
  },
  {
    path: '/tariffs',
    name: 'Тарифы',
    component: () => import('../views/tariffs/TariffsList.vue'),
    meta: {
      nav: true,
      group: 1
    }
  },
  {
    path: '/stat',
    name: 'Статистика',
    component: () => import('../views/Stat.vue'),
    meta: {
      nav: true,
      group: 1
    },
    children: [{
      path: ':user',
      name: 'Статистика по пользователю',
      component: () => import('../views/Stat.vue')
    }]
  },
  {
    path: '/balance',
    name: 'Пополнить баланс',
    component: () => import('../views/Balance.vue'),
    meta: {
      nav: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.auth);
  if (requireAuth && !store.getters['AuthService/token'])
      return next('/auth');
  if (to.path == "/auth" && store.getters["AuthService/token"])
      return next("/")
  next();
})



export default router
