import axios from 'axios'
import router from '../../router'
export default {
  namespaced: true,
  state: {
    isLogged: false,
    token: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')) || {},
    error: false,
    tariff: null,
    balance: 0
  },
  mutations: {
    authSuccess: (s, data) => { 
      s.error = false;
      s.token = data.token;
      s.user = data.user;
      s.isLogged = true;
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      router.push('/')
      
    },
    logout: s => {
      s.token = null;
      s.user = null; 
      s.isLogged = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user'); 
      router.push('/auth')
    },
    authFail: (s) => s.error = true
  },
  actions: {
    async getTariff({state}) {
      const {data} = await axios.post('/user/tariff', {user: state.user._id}); 
      state.tariff = data.tariff;
      state.balance = data.balance;
    },
    async submit({commit, dispatch}, body) {
      try {
        const {data: result} = await axios.post('/auth', body);
        console.log(result)
        commit('authSuccess', result); 
      } catch(e) {
        console.log(e)
        commit('authFail'); 
      }
    },
    logout({commit}) {
      commit('logout')
    }
  },
  getters: {
    isLogged: s => s.isLogged,
    token: s => s.token,
    user: s => s.user,
    error: s => s.error,
    tariff: s => s.tariff,
    balance: s => s.balance
  }
}