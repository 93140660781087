import { createStore } from 'vuex'
import AuthService from './modules/AuthService'
import UserService from './modules/UserService'
import BaseService from './modules/BaseService'
import SenderService from './modules/SenderService'
import ReportService from './modules/ReportService'



export default createStore({
  modules: {
    AuthService, UserService, BaseService, SenderService, ReportService
  }
})
