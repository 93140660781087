import axios from 'axios'
export default {
  namespaced: true,
  state: {
    base: {
      numbers: {},
      file: [],
      columns: [],
      uploadedFile: null
    },
    baseAll: [],
    baseCount : 0,
    preview: [],
    answer: []
  },
  mutations: {
    setBaseList: (s, base) => s.baseList = base,
    setBaseAll: (s, base) => {
      s.baseAll = base
    },
    setBaseCount: (s, count) => s.baseCount = count,
    setPreview: (s, res) => {
      s.preview = res.preview
      s.base.uploadedFile = res.file
    },
    clear: (s) => {
      s.base.numbers = {};
      s.base.file = [];
      s.base.columns = [];
      s.base.uploadedFile = null;
      s.preview = []
    }
  },
  actions: {
    async getBaseAll({commit, state}, filter) {
      const {data: base} = await axios.post("/base/all", {filter, page: filter.page}); 
      commit("setBaseAll", base.list);
      state.answer = base.ans;
      commit("setBaseCount", base.count);
    },
    async getBaseList({commit}) {
      const {data} = await axios.get('/base'); 
      commit("setBaseList", data); 
    },
    uploadSuccess({commit}, res) {
      commit("setPreview", res);  
    },
    clear({commit}) {
      commit("clear"); 
    }
  },
  getters: {
    base: s => s.base,
    preview: s => s.preview,
    baseList: s => s.baseList,
    baseAll: s => s.baseAll,
    baseCount: s => s.baseCount,
    answer: s => s.answer,
  }
}