import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import axios from "axios"
import moment from 'vue-moment'





axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token'); 
  if(token) config.headers["Authorization"] = "Bearer " + token;
  config.headers["Content-Type"] = "application/json";
  return config;
});

axios.interceptors.response.use(response => response, err => { 
  if(err.response?.status == 401 && !err.config._retry) {
    store.dispatch('AuthService/logout')
  }
});


axios.defaults.baseURL = 'https://prosto-bot.ru/api'; 

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

 

app.use(store).use(router).use(ElementPlus).mount('#app')
 
