import axios from 'axios'
export default {
  namespaced: true,
  state: {
    report: null,
    answered: 0,
    botanswer: 0,
    notanswered: 0,
    calls: 0,
    stat: {
      answered: 0,
      nocall: 0,
      bot: 0,
      conversion: 0,
      senderLaunched: 0,
      users: []
    }
  },
  mutations: {
    setReport: (s, report) => s.report = report
  },
  actions: {
    async getReport({commit, state}, sender) {
      const {data} = await axios.get("/report/sender/" + sender) 
      state.answered = data.answered;
      state.notanswered = data.notanswered;
      state.botanswer = data.botanswer;
      state.calls = data.calls;
      state.sender = data.sender;
      commit("setReport", data); 
    },
    async getUsers({state}) {
      const {data} = await axios.get('/report/users');
      state.stat.users = data;
    },
    async getStat({state}, {period, user}) {
      const {data} = await axios.post('/report/stat', {period, user}); 
      state.stat.answered = data.report.filter(e => e.answer1).length
      state.stat.nocall = data.report.filter(e => !e.answer1).length
      state.stat.bot = data.report.filter(e => e.answer1 && e.answer1.indexOf('оставить') > -1).length
      state.stat.conversion = state.stat.answered ? parseInt(state.stat.nocall / state.stat.answered * 100) : 0
      state.stat.senderLaunched = data.sender.length

      

    }
  },
  getters: {
    report: s => s.report,
    answered: s => s.answered,
    notanswered: s => s.notanswered,
    botanswer: s => s.botanswer,
    calls: s => s.calls,
    users: s => s.stat.users,
    stat: s => s.stat
  }
}