import axios from 'axios'
export default {
  namespaced: true,
  state: {
    users: [],
    user: {
     
    }
  },
  mutations: {
    setUsers: (s, users) => { s.users = users },
    setUser: (s, user) => { s.user = user }
  },
  actions: {
    async getUsers({commit}) {
      const {data: result} = await axios.get('/users');  
      commit('setUsers', result);  
    },
    async getUser({commit, state}, user_id) {
      const {data: result} = await axios.get('/user', {params: {user_id}}); 
      result.tariff =  result.tariff ? result.tariff._id : null;
      commit('setUser', result)
//      state.user.balance = result.balance;
    }
  },
  getters: {
    users: s => s.users,
    user: s => s.user,
  }
}