<template>
  <div class="sidebar" v-if="$route.path != '/auth'">
    <div>
      <div class="logo">
        <img src="@/assets/iqlogo.png">
      </div>
      <div class="profile">
        <div class="profile__ava">
          <img src="@/assets/ava.jpg">
        </div>
        <div class="profile__name">Alexander Sizhuk</div>
        <div class="profile__email">info@sizhuk.com</div>
      </div>
      <nav>
        <router-link v-for="item, index of menu" :key="index" :to="item.path">
          <img :src="require(`./assets/i${index + 1}.svg`)" alt="">
          {{ item.name }}
        </router-link>
        <a href="/offer" class="offer-link">Публичная оферта</a>
      </nav>
    </div>
    <!-- <div class="sidebar-user">
      <div class="user-image">
        <img src="@/assets/user.png">
      </div>
      <div class="user-data">
        <div class="user-name">Alexander Sizhuk</div>
        <div class="user-email">info@sizhuk.com</div>
      </div>
      <div class="logout" @click="logout()">
        <i class="fi fi-rr-sign-out-alt"></i>
      </div>
    </div> -->
  </div>
  <div class="content" :class="{ 'auth': $route.path == '/auth' }">
    <div class="balance" v-if="user._id">
      <div class="balance-sum">
        Тариф <strong>{{ tariff }}</strong> <span v-if="tariff">(8 коп./сек.)</span>
      </div>
      <div class="balance-sum">
        Баланс <strong>{{ balance }} ₽</strong>
      </div>
      <router-link to="/balance">Пополнить баланс</router-link>
      <a href="">Запросить счет</a>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  created() {
    if (this.user && this.user._id) this.getTariff()
  },
  methods: {
    ...mapActions('AuthService', ['logout', 'getTariff'])
  },
  computed: {
    ...mapGetters('AuthService', ['user', 'tariff', 'balance']),
    pageName() {
      return this.$route.name
    },
    menu() {
      return this.$router.options.routes.filter(e => e.meta && e.meta.nav === true && e.meta.group >= this.user.group)
    },

  }
}
</script>

<style>
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 14px;
  background: #F5F5F6;
}

.sidebar {
  flex-basis: 300px;
  background: #10446A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  flex-basis: calc(100% - 300px);
  max-width: calc(100% - 300px);
  height: 100%;
  overflow: auto;
}



.logo {
  font-weight: 600;
  color: white;
  font-size: 32px;
  padding: 25px 0px;
  background: linear-gradient(180deg, #187CB4 0%, #2699DA 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  padding-bottom: 40px;
}

.logo span {
  font-size: 16px;
  font-weight: 500;
}

nav a {
  text-decoration: none;
  color: white;
  text-align: left;
  display: block;
  padding: 10px 20px;
}

nav a.router-link-exact-active {
  background: linear-gradient(180deg, #187CB4 0%, #2699DA 100%);
  border-radius: 0px;
}



.content-header {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.header-buttons>* {
  margin: 0px 10px;
}

.page {
  padding: 20px 30px;
}

.el-select {
  width: 100%;
}

.field-desc {
  line-height: normal;
  padding: 5px 0px;
  text-align: left;
  color: gray;
  margin-bottom: 10px;
}

.field-hidden,
.field-inner {
  flex: 1 1 100%;
  text-align: left;
}

.field-inner {
  margin-top: 20px;
}

.auth {
  flex-basis: 100%;
  max-width: 100%;
}

.user-image img {
  width: 40px;
}

.user-image {
  flex-basis: 40px;
}

.sidebar-user {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #19b477;
}

.user-data {
  color: white
}

.user-name {
  font-weight: 600;
}

.user-data {
  margin-left: 15px;
  text-align: left;
}

.user-email {
  font-size: 14px;
}

.logout {
  color: rgb(69, 108, 85);
  flex-basis: 90px;
  text-align: right;
  font-weight: bold;
  font-size: 21px;
  line-height: 9px;
  cursor: pointer;
}

.el-page-header {
  flex-basis: 50%;
  display: flex;
}

.profile__ava {
  border-radius: 50%;
  overflow: hidden;
  width: 85px;
  height: 85px;
  background: linear-gradient(180deg, #187CB4 0%, #2699DA 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 10px;
}

.profile__ava img {
  border-radius: 50%;
}

.profile {
  text-align: center;
  color: white;
}

.profile__name {
  font-size: 16px;
}

.profile__email {
  font-size: 12px;
}

.profile {
  margin-top: -30px;
  margin-bottom: 30px;
}

nav a {
  display: flex;
  align-items: center;
}

nav a img {
  height: 21px;
  object-fit: none;
  width: 41px;
  margin-right: 10px;
}


.el-table {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.balance a {
  color: #187CB4;
  text-decoration: underline;
}

.balance {
  display: flex;
  justify-content: flex-end;
}

.balance>* {
  padding: 20px 15px;
}

.balance>*:last-child {
  padding-right: 30px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #10446A;
}

.el-button--primary {
  background: linear-gradient(180deg, #187CB4 0%, #2699DA 100%);
  border-radius: 4px;
}

.header-buttons>*:last-child {
  margin-right: 0px;
}

.el-input__wrapper {
  background: none;
}

.offer-link {
  display: block;
  position: absolute;
  bottom: 20px;
}
</style>
