import axios from 'axios'
import router from "@/router/index"
export default {
  namespaced: true,
  state: {
    running: false,
    list: [],
    total: 0,
    active: 0,
    form: {
      _id: null,
      ivr: [],
      name: '',
      speed: 80,
      silence: false,
      cron: {
        active: false,
        time: [0, 0],
        days: []
      },
      record: false,
      repeat: {
        enabled: false,
        num: 15
      },
      audio: null,
      number_source: "buffer",
      numbers: {
        buffer: '',
        manual: [
          {
            value: ''
          }
        ]
      },
      base: null
    },
  },
  mutations: {
    setList: (s, list) => { s.list = list },
    setActive: (s, active) => { s.active = active }, 
    setForm: (s, form) => { s.form = form; s.form.ivr = JSON.parse(form.ivr), s.form.timeLimitDays = form.timeLimitDays ? JSON.parse(form.timeLimitDays) : [], s.form.cron = JSON.parse(form.cron) }, 
    setRunning: (s, running) => s.running = running
  },
  actions: {
    async get({commit}, id) {
      const {data} = await axios.get("/sender/" + id);
      commit("setForm", data); 
    },
    async getActive({state}) {
      const {data} = await axios.post("/sender/active/all"); 
      for(let i in state.list) {
        state.list[i].active = data.active.filter(e => e.sender == state.list[i]._id).length
        state.list[i].pause = data.pause.filter(e => e.includes(state.list[i]._id)).length
      }
    },
    async getList({commit, state, dispatch}, page = 1) {
      const {data: result} = await axios.post('/sender', {page});   
      commit('setList', result.list);  
      commit('setActive', result.active);  
      state.total = result.total; 
    },
    async report({dispatch}, sender) {
      const promises = [];
      for(let i of sender) {
        promises.push(dispatch('reportSend', i))
      }
 
    },
    async reportSend({state}, id) {
      const {data} = await axios.post('/sender/report', {sender: id}); 
      const index = state.list.findIndex(e => e._id == id)
      state.list[index]['calls'] = data.length
      state.list[index]['calls_success'] = data.filter(e => e.report.some(k => k.type == 'ANSWER')).length
    },
    async remove({dispatch}, id) {
      if(!confirm('Удалить рассылку?')) return false;
      await axios.post("/sender/delete", {id});
      dispatch("getList"); 
    },
    async update({state}) {
      await axios.post("/sender/update", {form: state.form})
      router.push("/") 
    },
    // async getActive({state}, sender) {
    //   const {data} = await axios.post("/sender/active", {sender});
    //   state.list[state.list.findIndex(e => e._id == sender)].active = data.active; 
    // },
    async run({commit, dispatch, state}, body) {
      commit("setRunning", true);
      await axios.post("/sender/run", body);
      commit("setRunning", false);
      dispatch('getActive', body.sender) 
    //  dispatch("getList");  
    },
    async stop({dispatch, state}, id) {
      await axios.post("/sender/stop", {id});
      state.list[state.list.findIndex(e => e._id == id)].active = 0;  
      dispatch("getList");  
    },
    async pause(_, id) {
      await axios.post("/sender/pause", {id});  
    },
    async start(_, id) {
      await axios.post("/sender/start", {id});  
    },
    async copy({dispatch}, form) {
      form.copy = 1;
      await axios.post("/sender/add", form);
      dispatch("getList");
    },
    async approve({dispatch}, id) {
      await axios.post("/sender/approve", {id});
      dispatch("getList");
    }
  },
  getters: {
    list: s => s.list,
    active: s => s.active, 
    form: s => s.form,
    running: s => s.running,
    total: s => s.total
  }
}